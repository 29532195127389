@font-face {
  font-family: $font-family-icomoon;
  src: url('font_icons/icomoon.eot?g9g5pm');
  src: url('font_icons/icomoon.eot?g9g5pm#iefix') format('embedded-opentype'),
    url('font_icons/icomoon.ttf?g9g5pm') format('truetype'),
    url('font_icons/icomoon.woff?g9g5pm') format('woff'),
    url('font_icons/icomoon.svg?g9g5pm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $font-family-icomoon !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-back_arrow:before {
  content: '\e900';
}
