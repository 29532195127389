.main-video-container {
  display: flex;
  flex-direction: column;
  //   width: 240px;
  height: 400px;
  align-items: center;
  justify-content: center;
}

.button-bottom-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 126px;
  background-color: rgba(255, 255, 255, 1);
  width: 657px;
  padding: 10px;
  opacity: 4;
  border-radius: 10px;
  margin-top: 5px;
}

.volume-time-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

// video::-webkit-media-controls-panel {
//   display: none !important;
// }
