//all varibles goes under this files
$black: #000;
//font-family
$font-family-base: 'Inter';
$font-family-icomoon: 'icomoon';

$white: #fff;
$primary: #2d5bff;
$dividerBorderColor: #bdbdbd;
$textColor: #1a2128;
$grayColor200: #333;
$switchDisabledBorderColor: #dadada;
$grayColor: #8c9093;
$delete-modal-subtitle: rgb(234, 84, 85);

$borderColor: #ccc;
$borderBgColor: #eeeeee;
$backgroundColor: #f1f4f5;
$scrollBarBgColor: rgba(105, 129, 155, 0.2);

$borderColor: #ccc;
$question-header-border-bottom: #98a8b3;
$borderBgColor: #eeeeee;
$backgroundColor: #edf2f3;
$scrollBarBgColor: rgba(105, 129, 155, 0.5);
$adminLoginbackgroundColor: #525fbd;

//progress stats color
$progressError: #e55957;
$progressSuccess: #009f46;
$progressWarning: #f6841b;
$progressStrong: #ffc30b;

//progress status bg color with opacity
$progressErrorBg: rgba(229, 89, 87, 0.585);
$progressSuccessBg: rgba(0, 159, 69, 0.486);
$progressWarningBg: rgba(246, 133, 27, 0.545);
$progressStrongBg: rgba(255, 194, 11, 0.495);
$errorTextColor: #ff1943;

//table
$table-bg-color: #fff;
$table-header: rgba(18, 20, 51, 0.9);
$table-checkbox-checked: #4e2788;
$table-checkbox-unchecked: #e9e9f8;
$table-hover: rgba(233, 233, 248, 0.4);
$pagination-selected-bd: #31125f;
$pagination-font: #ffffff;
$table-sort-icon: #aeaeae;

//modal
$delete-modal-subtitle: rgb(234, 84, 85);
$card-border-grey: #98a8b380;

//icon-colors

$back-arrow-icon: #4c5b67;
$text-icon-color: rgba(245, 164, 7, 1);
$audio-icon-color: rgba(67, 57, 242, 1);
$video-icon-color: rgba(13, 173, 242, 1);
